import aspida from '@aspida/axios'
import api from 'api/$api'
import axios from 'axios'
import Constants from 'helpers/constants'
import Cookies from 'js-cookie'

export const token = Cookies.get(Constants.cookieName)

const headers = {
  'Content-Type': 'application/json',
}

type UrlType = { [key: string]: string }
const devPort = process.env.DEVELOPMENT_API_PORT || '3001'

export const urls: UrlType = {
  development:
    process.env.NEXT_PUBLIC_API_URL_DEV || `http://127.0.0.1:${devPort}/api`,
  staging:
    process.env.NEXT_PUBLIC_API_URL ||
    'https://app-stg-dot-artcloud-311007.an.r.appspot.com/api',
  // TODO: artx.comnに変更
  production: 'https://api.theartx.app',
}

const env = process.env.NEXT_PUBLIC_NODE_ENV || 'development'

const instance = axios.create({
  baseURL: urls[env],
  headers: headers,
})

if (token) {
  instance.defaults.headers.Authorization = `Token token=${token}`
}

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve({
      data: response.data,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      config: response.config,
    })
  },
  (error) => {
    switch (error.response?.status) {
    default: {
      return Promise.resolve({
        error: error.response,
      })
    }
    }
  },
)

export default instance

// TODO: aspidaを使ったclient,置き換えが終わったら旧clientは削除
export const apiClient = api(aspida(instance))
