import { message, ModalProps, Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import api from "api"
import { Button } from "components/shared/Button"
import { Loading } from "components/shared/Loading"
import { Modal } from "components/shared/Modal"
import Constants from "helpers/constants"
import Cookies from "js-cookie"
import { useTranslation } from "next-i18next"
import { CSSProperties, FC, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"

import { GuestBookArtxLogo } from "../customers/GuestBookArtxLogo"

export type SignUpValues = {
  nickname: string
  email: string
  password: string
  first_name: string
  last_name: string
  phone_number: string
  confirm_check_box: string
};

export const SignUpModal: FC<ModalProps & { notification: string, onSuccess: () => void, onSignIn: () => void }> =
  ({ visible, onCancel, onSuccess, onSignIn }) => {
    const { xs } = useBreakpoint()
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm<SignUpValues>({ mode: "onChange" })

    const onSubmit = (data: SignUpValues) => {
      handleSignUp(data)
    }

    const handleSignUp = async (values: SignUpValues) => {
      try {
        setLoading(true)

        const { data: user, error }: { data: { token: string }; error: any } = await api.post("/sign_up", values)

        setLoading(false)

        if (user) {
          Cookies.set(Constants.cookieName, user.token, { expires: 60 })
        }

        if (error) {
          message.error(error.data.error)
        } else {
          onSuccess()
        }
      } catch (error: any) {
        message.error(error.data.error)
      }
    }

    const labelStyle = {
      color: "var(--Black, #232427)",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NotoSansJP",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "100%",
      letterSpacing: 0.8
    }

    const inputStyle = {
      display: "flex",
      padding: 16,
      height: 48,
      alignItems: "flex-end",
      gap: 12,
      alignSelf: "stretch",
      borderRadius: 1,
      border: "1px solid var(--AX---Gray-3, #C4C4C4)",
      background: "var(--White, #FFF)",
      "::placeholder": {
        color: "var(--AX---Gray-3, #C4C4C4)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "NotoSansJP",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "100%",
        letterSpacing: 0.8
      }
    }

    const formItemStyle: CSSProperties = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 8,
      alignSelf: "stretch",
    }

    if (loading) return <Loading />

    return (
      <StyledModal
        footer={null}
        visible={visible}
        width={xs ? 343 : 1040}
        onCancel={onCancel}
        loading={loading}
      >
        <div
          style={{
            display: "flex",
            flexDirection: xs ? "column-reverse" : "row",
            width: xs ? 343 : 1039,
            height: xs ? "auto" : 600,
            alignItems: xs ? "center" : undefined,
            justifyContent: "center",
            gap: xs ? 32 : undefined,
            scrollbarWidth: "none"
          }}
        >
          <GuestBookArtxLogo />
          <div
            style={{
              display: "flex",
              padding: xs ? "40px 16px 60px 16px" : "60px 40px",
              flexDirection: "column",
              alignItems: "center",
              gap: 24,
              flex: "1 0 0",
              borderRadius: xs ? 8 : "0px 8px 8px 0px",
              border: "1px solid var(--AX---Gray-2, #ECECEC)",
              background: "var(--AX---White, #FFF)",
              boxShadow: "0px 2px 2px -1px rgba(61, 47, 142, 0.10), 0px 1px 1px 0px rgba(61, 47, 142, 0.04), 0px 2px 8px 0px rgba(61, 47, 142, 0.08)",
              alignSelf: xs ? "stretch" : undefined,
              width: xs ? "100%" : "50%",
              height: "auto"
            }}
          >
            <Typography.Text
              style={{
                color: "var(--Black, #232427)",
                textAlign: "center",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "NotoSansJP",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "100%",
                letterSpacing: 0.8,
                flex: "1 0 0"
              }}
            >
              {t("新規登録")}
            </Typography.Text>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 24,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 24,
                }}
              >
                <div style={formItemStyle}>
                  <label style={labelStyle}>{t("ユーザー名")}</label>
                  <input style={inputStyle} {...register("nickname", { required: true })} placeholder={t("ユーザー名を入力してください")} />
                  {errors.email && <p style={{ ...labelStyle, color: "red" }}>{t("ユーザー名")}{t("は必須です")}</p>}
                </div>
                <div style={formItemStyle}>
                  <label style={labelStyle}>{t("ID(メールアドレス)")}</label>
                  <input type="email" style={inputStyle} {...register("email", { required: true })} placeholder={t("IDを入力してください")} />
                  {errors.email && <p style={{ ...labelStyle, color: "red" }}>{t("メールアドレス")}{t("は必須です")}</p>}
                </div>

                <div style={formItemStyle}>
                  <label style={labelStyle}>{t("パスワード")}</label>
                  <input type="password" style={inputStyle} {...register("password", { required: true })} placeholder={t("パスワードを入力してください")} />
                  {errors.password && <p style={{ ...labelStyle, color: "red" }}>{t("パスワード")}{t("は必須です")}</p>}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 16
                }}
              >
                <div style={formItemStyle}>
                  <label
                    style={{
                      display: "flex",
                      padding: "4px 0px",
                      alignItems: "center",
                      gap: 8,
                      alignSelf: "stretch",
                      cursor: "pointer"
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        display: "flex",
                        width: 20,
                        height: 20,
                        padding: "7px 5px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 10
                      }}
                      {...register("confirm_check_box", { required: true })}
                    />
                    <Typography.Text
                      style={{ fontSize: xs ? 12 : 14, lineHeight: 1.5 }}

                    >
                      <span>
                        {t("ArtXの")}
                        <a
                          href="https://dashboard.cloud.artx.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "underline" }}
                        >
                          {t("利用規約")}
                        </a>
                        {t("と")}
                        <a
                          href="https://dashboard.cloud.artx.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "underline" }}
                        >
                          {t("プライバシーポリシー")}
                        </a>
                        {t("に同意する")}
                      </span>
                    </Typography.Text>
                  </label>
                  {errors.confirm_check_box && <p style={{ ...labelStyle, color: "red" }}>{t("利用規約とプライバシーポリシーへの同意は必須です")}</p>}
                </div>

                <div>
                  <Button
                    type="primary"
                    fontWeight="normal"
                    onClick={(e) => handleSubmit(onSubmit)(e)}
                    style={{ display: "flex" }}
                  >
                    {t("新規登録")}
                  </Button>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "var(--Black, #232427)",
                      textAlign: "center",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "NotoSansJP",
                      fontSize: 14,
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "100%",
                      letterSpacing: 0.8,
                      textDecorationLine: "underline"
                    }}
                    onClick={onSignIn}
                  >
                    {t("アカウントをお持ちの方はこちら")}
                  </a>
                </div>
              </div>

            </form>
          </div>
        </div>
      </StyledModal>
    )
  }

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0px;
    overflow: auto;
  }

  .ant-modal-content {
    border-radius: 8px;
  }
`
